import { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography, Button, Input } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import constants from 'common/constants';
import imgSuffix from 'common/image-suffix';
import { db } from 'services/firebase/firebaseConfig';
import { query, collection, orderBy, startAfter, limit, getDocs, where, doc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from 'firebase/analytics'
import colors from "theme/colors";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import { Functions } from 'common/functions';
import TiktokPixel from 'utils/tiktok_pixel';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const desc_index = client.initIndex('firebase_products_createdAt_desc');
const productsStores_index = client.initIndex('firebase_products_stores');

var last_search = "";

const Products = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const [carouselType, setCarouselType] = useState(location.state && location.state.type ? location.state.type : null)
    const [products, setProducts] = useState([]);
    const [skipBestDealsProducts, setSkipBestDealsProducts] = useState(null);
    const [skipCategoryProducts, setSkipCategoryProducts] = useState(0);
    const [skipRecentProducts, setSkipRecentProducts] = useState(0);
    const limitProducts = 15;
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allProductsDisplayed, setAllProductsDisplayed] = useState(false);
    const [persist_scroll, set_persist_scroll] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [init, setInit] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState("");

    useEffect(() => {
        const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
            firebase_screen: "ListaProductos_Web", 
            firebase_screen_class: "ListaProductos_Web"
		});
        TiktokPixel.pageView();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let filter = urlParams.get("filter")

        let storageItem = sessionStorage.getItem("products_persist")
        let products_persist = JSON.parse(storageItem)
        if(products_persist){
            console.log("productos: ", products_persist.products)
            set_persist_scroll(products_persist.scrollY)
            setSkipBestDealsProducts(new Date(products_persist.skipBestDealsProducts))
            setSkipCategoryProducts(products_persist.skipCategoryProducts)
            setSkipRecentProducts(products_persist.skipRecentProducts)
            setProducts(products_persist.products)
            setLoading(products_persist.loading)
            setLoadingMore(products_persist.loadingMore)
            setAllProductsDisplayed(products_persist.allProductsDisplayed)
            setSearchText(products_persist.searchText)
            setSelectedSubcategory(products_persist.selectedSubcategory)
            setSubCategories(products_persist.subCategories)
            sessionStorage.removeItem("products_persist")
        }else{
            window.scrollTo(0, 0)
            if(carouselType == "category"){
                logEvent(analytics, 'view_item_list', {
                    item_list_id: "category_products_Web",
                    item_list_name: "Productos por categoria_Web"
                });
                getCategoryProducts()
                getSubCategories()
            }else if(carouselType == "offers" || filter == "offers"){
                logEvent(analytics, 'view_item_list', {
                    item_list_id: "offers_Web",
                    item_list_name: "Promociones_Web"
                });
                getBestDeals()
            }else if(carouselType == "recent" || filter == "recent"){
                logEvent(analytics, 'view_item_list', {
                    item_list_id: "recent_products_Web",
                    item_list_name: "Productos recientes_Web"
                });
                getRecentProducts()
            }else{
                setLoading(false)
            }
        }
        setInit(true)
	},[])

    useEffect(()=>{
        if(init == true){
            if(persist_scroll && products.length > 0){
                window.scrollTo(0, persist_scroll)
                set_persist_scroll(0)
            }
        }
    },[products, persist_scroll, init])

    const getSubCategories = async () => {
        try{
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let category = urlParams.get("category")
            const docRef = doc(db, constants.mode + "crop_categories", category)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                let subcategories = Object.values(docSnap.data().sub)
                setSubCategories(subcategories)
                // console.log("Document data:", subcategories);
            }
        }catch(error){
            console.log("subcategorías: ", error)
        }
    }

    const getCategoryProducts = async () => {
        setLoadingMore(true)
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let category = urlParams.get("category")
            let filters = ``
            if(selectedSubcategory != ""){
                filters = `cropCategories.lvl0:"${category}" AND cropCategories.lvl1:"${category + " > " + selectedSubcategory}" AND ownerType:"store"`
            }else{
                filters = `cropCategories.lvl0:"${category}" AND ownerType:"store"`
            }
            productsStores_index.search(searchText, {
                filters: filters,
                page: skipCategoryProducts,
                ruleContexts: "client"
            }).then(({ hits }) => {
                // console.log("Product hits: ", hits)
                if(hits.length < 15){
                    setAllProductsDisplayed(true)
                }
                setSkipCategoryProducts(skipCategoryProducts + 1)
                let auxProducts = []
                hits.forEach((doc) => {
                    auxProducts.push({
                        ...doc,
                        uid: doc.objectID,
                        price: parseFloat(doc.price)
                    })
                });
                setLoadingMore(false)
                setLoading(false)
                setProducts([])
                setProducts([...products, ...Functions.shuffleArray(auxProducts)])
            });
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("productos con categoría: ", error)
        }
    }

    const getBestDeals = async () => {
        setLoadingMore(true)
		try {
            let docSnap = null
            if(skipBestDealsProducts){
                const collRef = collection(db, constants.mode + "products")
                const docsRef = query(collRef, orderBy("promotion", "desc"), where("promotion", "!=", ""), where("ownerType", "==", "store"), where("disabled","==",false), orderBy("stripe_payment", "desc"), orderBy("createdAt", "desc"), startAfter(skipBestDealsProducts), limit(limitProducts))
                docSnap = await getDocs(docsRef);
            }else{
                const collRef = collection(db, constants.mode + "products")
                const docsRef = query(collRef, orderBy("promotion", "desc"), where("promotion", "!=", ""), where("ownerType", "==", "store"), where("disabled","==",false), orderBy("stripe_payment", "desc"), orderBy("createdAt", "desc"), limit(limitProducts))
                docSnap = await getDocs(docsRef);
            }
            if(docSnap.docs.length > 0){
                if(docSnap.docs.length < 15){
                    setAllProductsDisplayed(true)
                }
                setSkipBestDealsProducts(docSnap.docs[docSnap.docs.length-1])
                var newProducts = []
                await docSnap.forEach((doc) => {
                    const product = {
                        uid: doc.id,
                        ...doc.data(),
                        price: parseFloat(doc.data().price),
                    }
                    newProducts.push(product)
                });
                newProducts = Functions.shuffleArray(newProducts)
                setProducts([...products, ...newProducts])
                setLoading(false)
                setLoadingMore(false)
            }else{
                setAllProductsDisplayed(true)
                setLoading(false)
                setLoadingMore(false)
            }
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("productos: ", error)
        }
	} 

    const getRecentProducts = async () => {
        try{
			let filters = `NOT store.label:"Treembo Store" AND ownerType:"store"`
			desc_index.search("", {
				filters: filters,
                page: skipRecentProducts,
                ruleContexts: "client"
			}).then(({ hits }) => {
				// console.log("Product hits: ", hits)
                if(hits.length < 15){
                    setAllProductsDisplayed(true)
                }
                setSkipRecentProducts(skipRecentProducts + 1)
				let auxProducts = []
				hits.forEach((doc) => {
					auxProducts.push({
						...doc,
						uid: doc.objectID,
                        price: parseFloat(doc.price),
					})
				});
                auxProducts = Functions.shuffleArray(auxProducts)
				setLoadingMore(false)
                setLoading(false)
				setProducts([])
				setProducts([...products, ...auxProducts])
			});
		}catch(error){
			console.log("RECENT PRODUCTS ERROR: ", error)
			setLoadingMore(false)
            setLoading(false)
		}
    }

    const moreProducts = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let filter = urlParams.get("filter")

        if(loadingMore == false && carouselType == "category"){
            getCategoryProducts()
        }else if(loadingMore == false && (carouselType == "offers" || filter == "offers")){
            getBestDeals()
        }else if(loadingMore == false && (carouselType == "recent" || filter == "recent")){
            getRecentProducts()
        }
    }

    const handleSubmit = (e) => {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        if(last_search != searchText){
            if(searchText.length > 2 || searchText.length == 0){
                if(searchText.length > 2){
                    const analytics = getAnalytics();
                    logEvent(analytics, 'search', {
                        search_term: searchText
                    });
                }
                setLoadingMore(false)
                setLoading(true)
                setAllProductsDisplayed(false)
                setSkipCategoryProducts(0)
                setProducts([])
                set_persist_scroll(0)
            }
        }
    }, [searchText])

    useEffect(() => {
        if(persist_scroll == 0 && last_search != searchText && products.length == 0){
            last_search = searchText
            getCategoryProducts()
        }
    },[products])

    const selectSubcategory = (subcategory) => {
        setLoadingMore(false)
        setLoading(true)
        setAllProductsDisplayed(false)
        setSkipCategoryProducts(0)
        setProducts([])
        set_persist_scroll(0)
        if(selectedSubcategory == subcategory){
            setSelectedSubcategory("")
        }else{
            setSelectedSubcategory(subcategory)
        }
    }

    useEffect(() => {
        if(init == true){
            if(persist_scroll == 0 && products.length == 0){
                getCategoryProducts()
            }
        }
    }, [selectedSubcategory])

    const openProduct = async (product) => {
        //console.log("producto: ", product)
        let products_persist = {
            products,
            skipBestDealsProducts,
            skipCategoryProducts,
            skipRecentProducts,
            loading,
            loadingMore,
            allProductsDisplayed,
            scrollY: window.scrollY,
            searchText,
            subCategories,
            selectedSubcategory
        }
        sessionStorage.setItem("products_persist", JSON.stringify(products_persist))
        if(product.objectID){
            navigate(`/product/${product.objectID}`)
        }else{
            navigate(`/product/${product.uid}`)
        }
    }

    const _renderSectionTitle = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get("filter")){
            let filter = urlParams.get("filter")
            if(filter == "offers"){
                return  "Promociones"
            } else if(filter == "recent"){
                return  "Productos recientes"
            } else{
                return "Tree-Commerce"
            }
        } else if(urlParams.get("category")){
            let category = urlParams.get("category")
            return  category.charAt(0).toUpperCase() + category.slice(1)
        }
    }

    const _renderSearcher = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get("category")){
            let category = urlParams.get("category")
            return  <Input
                        placeholder={"Buscar para " + category.charAt(0).toUpperCase() + category.slice(1)}
                        sx={{ width: "100%", height: "32px", marginTop: "16px", paddingLeft: "16px", paddingRight: "16px", border: "1px solid " + colors.medium_light_gray, borderRadius: "16px" }}
                        disableUnderline={true}
                        onChange={handleSubmit}
                        value={searchText}
                    />
        }
    }

	return (
		<Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px", alignItems: "center"}}>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", position: "sticky", top: "8rem", backgroundColor: "white", zIndex: 5000, paddingTop: "16px", alignItems: "center" }}>
                <ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={()=>{navigate(-1)}}/>
                <Typography variant='title4'>
                    {_renderSectionTitle()}
                </Typography>
            </Box>
            {_renderSearcher()}
            {subCategories.length > 0 ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", overflowX: "scroll", overflowY: "hidden", marginTop: "16px", scrollbarWidth: "none"}}>
                    {subCategories.map((subcategory, index)=>{
                        return  <Box onClick={()=>selectSubcategory(subcategory.id)} sx={{ display: "flex", backgroundColor: selectedSubcategory == subcategory.id ? colors.primary_green : colors.light_gray, height: "24px", justifyContent: "center", alignItems: "center", borderRadius: "14px", marginRight: "8px", textOverflow: "ellipsis", paddingLeft: "16px", paddingRight: "16px", whiteSpace: "nowrap", width: "fit-content", cursor: "pointer" }}>
                                    <Typography variant='body3' sx={{ display: "flex", color: selectedSubcategory == subcategory.id ? "white" : "black" }}>{subcategory.es}</Typography>
                                </Box>
                    })} 
                </div>
            :<></>}
            {loading == true ?
                <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography>
                        Cargando...
                    </Typography>
                </Box>
            :
                products && products.length > 0 ?
                    <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                        {products.map((product, index)=> {
                            if(product){
                                return  <div key={index}>
                                            <Grid item onClick={()=>openProduct(product)} sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", minHeight: "100px", marginTop: "4px", justifyContent: "center", alignItems: "center", cursor: "pointer", marginBottom: "4px" }}>
                                                <Grid item xs={3} sm={2} md={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", borderRadius: "8px", overflow: "hidden", position: "relative" }}>
                                                    {product.images ?
                                                        <img
                                                            alt="img"
                                                            src={product.images[0] + imgSuffix.md}
                                                            style={{display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1"}}
                                                        /> 
                                                    : (product.legacy_images ? 
                                                        <img
                                                            alt="img"
                                                            src={product.legacy_images[0] + "480x480"}
                                                            style={{display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1"}}
                                                        /> 
                                                    : "")}
                                                </Grid>
                                                <Grid item xs={9} sm={10} md={10} sx={{ marginLeft: "16px", cursor: "pointer" }}> 
                                                    <Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.name}</Typography>
                                                    <Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{product.description}</Typography>
                                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                        {product.price && product.price != 0 && product.price != "" && product.price != null ?
                                                            <Typography variant="title8">${product.price.toLocaleString("en-US")} {product.currency}</Typography>
                                                        :null}
                                                        {product.promotion != "" && product.promotion != null ?
                                                            <Box sx={{display: "flex", backgroundColor: "sunset", marginLeft: "8px", height: "20px", borderRadius: "12px", paddingLeft: "8px", paddingRight: "8px", alignItems: "center"}}>
                                                                <Typography variant="body4" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, color: "white", marginTop: "2px" }}>{product.promotion}</Typography>
                                                            </Box>
                                                        :null}
                                                    </Box>
                                                    <Typography variant="body2" sx={{ color: "gray", display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.address && product.address.name != "" ? product.address.name : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                            <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }}/>
                                        </div>
                            }else{
                                return  <Box>
                                            <Typography>Producto no disponible</Typography>
                                        </Box>
                            }
                        })}
                        {loadingMore == true ? 
                            <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Typography>
                                    Cargando...
                                </Typography>
                            </Box>
                        :
                            !allProductsDisplayed ?
                                <Button sx={{ marginTop: "8px" }} onClick={()=>moreProducts()}>
                                    <Typography variant='title8' sx={{ color: colors.primary_green }}>Ver más...</Typography>
                                </Button>
                            :null
                        }
                        
                    </Grid>
                :
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            No hay productos
                        </Typography>
                    </Box>
            }
        </Container>
	)
}

export default Products;