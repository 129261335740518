import { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography, Button, Input } from '@mui/material';
import { PlaceOutlined, Verified } from '@mui/icons-material';
import constants from 'common/constants';
import imgSuffix from 'common/image-suffix';
import { db } from 'services/firebase/firebaseConfig';
import { query, collection, orderBy, startAfter, limit, getDocs, where } from "firebase/firestore";
import colors from "theme/colors";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import TiktokPixel from 'utils/tiktok_pixel';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const index = client.initIndex('firebase_products');
const productsStores_index = client.initIndex('firebase_products_stores');

var last_search = "";

const StoreProductsScreen = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [store, setStore] = useState(location.state && location.state.store ? location.state.store : null);
    const [storeId, setStoreId] = useState(null);
    const [username, setUsername] = useState(params.username || null);
    const [categoryFilter, setCategoryFilter] = useState("");
    const [categoryValue, setCategoryValue] = useState("")
    const [products, setProducts] = useState([]);
    const [skipProducts, setSkipProducts] = useState(0);
    const limitProducts = 15;
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allProductsDisplayed, setAllProductsDisplayed] = useState(false);
    const [persist_scroll, set_persist_scroll] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [init, setInit] = useState(false);

    useEffect(() => {
        TiktokPixel.pageView();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let category = urlParams.get("category")
        let filter = `categories.${category}.id`
        if(category){
            setCategoryFilter(filter)
            setCategoryValue(category)
        }
        if(store != null){
            getPersist()
        }else{
            if(username){
                getStore()
            }else{
                setLoading(false)
            }
        }
	},[])

    const getPersist = () => {
        //console.log("hay tienda")
        let storageItem = sessionStorage.getItem("store_products_persist")
        let products_persist = JSON.parse(storageItem)
        if(products_persist){
            //console.log("regresamos de un producto")
            set_persist_scroll(products_persist.scrollY)
            setSkipProducts(products_persist.skipProducts)
            setProducts(products_persist.products)
            setLoading(products_persist.loading)
            setLoadingMore(products_persist.loadingMore)
            setAllProductsDisplayed(products_persist.allProductsDisplayed)
            setSearchText(products_persist.searchText)
            sessionStorage.removeItem("store_products_persist")
        }else{
            //console.log("solo obtenemos productos")
            getProducts()
        }
        setInit(true)
    }

    useEffect(()=>{
        if(init == true){
            if(persist_scroll && products.length > 0){
                //console.log("solo se acomoda el scroll")
                window.scrollTo(0, persist_scroll)
                set_persist_scroll(0)
            }else if(last_search != searchText && String(searchText).trim().length == 0 && products.length == 0){
                //console.log("obtiene productos")
                getProducts()
            }
        }
    },[products, persist_scroll, init])

    useEffect(()=>{
        if(storeId){
            getPersist()
        }
    },[storeId])

    const getStore = async () => {
        try {
            const colRefStores = collection(db, constants.mode + "stores")
            const docsRefStores = query(colRefStores, where("username", "==", username))
            const docSnapStore = await getDocs(docsRefStores);
            //console.log("DOCS: ", docSnapStore.size)
			if(!docSnapStore.empty){
                let s = docSnapStore.docs[0]
				let data = {
					...s.data(),
					uid: s.id
				}
				setStore(data);
                setStoreId(s.id);
			}else{
				setLoading(false)
			}
		} catch (error) {
			setLoading(false)
			console.log("Get store error: ", error)
		}
    }

    const getProducts = async () => {
        setLoadingMore(true)
		try {
            let filters = `store.label:"${store.name}"`
			productsStores_index.search("", {
				filters: filters,
                page: skipProducts,
				ruleContexts: "client"
			}).then(({ hits }) => {
				if(hits.length < 15){
                    setAllProductsDisplayed(true)
                }
                setSkipProducts(skipProducts + 1)
				let newProducts = []
				hits.forEach((doc) => {
					newProducts.push({
						...doc,
						uid: doc.objectID,
						price: parseFloat(doc.price),
                        uniqueCategory: doc.categories ? Object.keys(doc.categories)[0] : "",
					})
				});
				setProducts([...products, ...newProducts])
                setLoading(false)
                setLoadingMore(false)
			});
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("productos: ", error)
        }
	} 

    const moreProducts = async () => {
        if(loadingMore == false){
            getProducts()
        }
    }

    const handleSubmit = async (event) => {
        setSearchText(event.target.value)
        if(last_search != event.target.value){
            if(String(event.target.value).trim().length > 2){
                last_search = event.target.value
                setLoading(true)
                handleSearch()
            }else if(String(event.target.value).trim().length == 0){
                //Necesito limpiar esto para la busqueda
                setSkipProducts(0)
                setProducts([])
                setAllProductsDisplayed(false)
                setLoading(true)
                //getProducts()
            }
        }
    }

    const handleSearch = () => {
		try {
			let filters = ``
			index.search(searchText, {
				filters: filters
			}).then(({ hits }) => {
				//console.log("Hits: ", hits.length)
				setLoading(false)
				setProducts([])
				setProducts(prev => [...hits])
                setAllProductsDisplayed(true)
			});
		} catch (error) {
			console.log("Error: ", error)
			setLoading(false)
		}
	}

    const openProduct = async (product) => {
        //console.log("producto: ", product)
        let products_persist = {
            products,
            skipProducts,
            loading,
            loadingMore,
            allProductsDisplayed,
            scrollY: window.scrollY,
            searchText
        }
        sessionStorage.setItem("store_products_persist", JSON.stringify(products_persist))
        if(product.objectID){
            navigate(`/product/${product.objectID}`)
        }else{
            navigate(`/product/${product.uid}`)
        }
    }

    try {
        if(loading){
            return <Container>
                        <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography>
                                Cargando...
                            </Typography>
                        </Box>
                    </Container>
        }
        return (
            store != null ?
                <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px", alignItems: "center"}}>
                    <Grid container maxWidth="4" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                        <Box sx={{ width: "100px", height: "100px", borderRadius: "50px", overflow: "hidden", backgroundColor: "#" + store.backgroundColor, alignSelf: "center" }}>
                            <img
                                alt="img"
                                src={store.logo + imgSuffix.md} 
                                height={100} 
                                width={100}
                                style={{ borderRadius: "50px", objectFit: "contain"}}
                            /> 
                        </Box>
                        <Grid item sx={{ display: "flex", flexDirection: "row", alignSelf: "center", justifyContent: "center", alignItems: "center", marginTop: "16px" }}>
                            <Typography variant="title6Bold" style={{ textAlign: "center" }}>{store.name}</Typography>
                            <Verified sx={{ fontSize: "18px", marginLeft: "4px", color: "#1fadc1" }}/>
                        </Grid>
                        <Grid item sx={{ display: "flex", flexDirection: "row", marginTop: "4px", alignSelf: "center" }}>
                            <PlaceOutlined fontSize="small" sx={{ color: colors.primary_green}}/>
                            <Typography variant="body2" sx={{ marginLeft: "4px", color: colors.primary_green }}>{store.location}</Typography>
                        </Grid>
                        {/* <Input
                            placeholder='Buscar productos'
                            sx={{ width: "100%", height: "32px", paddingLeft: "16px", paddingRight: "16px", border: "1px solid " + colors.medium_light_gray, borderRadius: "16px" }}
                            disableUnderline={true}
                            onChange={(event)=>handleSubmit(event)}
                            value={searchText}
                        /> */}
                        {products && products.length > 0 ?
                            <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                                {products.map((product, index)=> {
                                    if(product){
                                        return  <div key={index}>
                                                    <Grid item onClick={()=>openProduct(product)} sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", minHeight: "100px", marginTop: "4px", justifyContent: "center", alignItems: "center", cursor: "pointer", marginBottom: "4px" }}>
                                                        <Grid item xs={3} sm={2} md={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", borderRadius: "8px", overflow: "hidden", position: "relative"}}>
                                                            {product.images ?
                                                                <img
                                                                    alt="img"
                                                                    src={product.images[0] + imgSuffix.md}
                                                                    style={{display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1"}}
                                                                /> 
                                                            : (product.legacy_images ? 
                                                                <img
                                                                    alt="img"
                                                                    src={product.legacy_images[0] + "480x480"}
                                                                    style={{display: "flex", width: "100%", objectFit: "cover", height: "5.4rem", aspectRatio: "1:1"}}
                                                                /> 
                                                            : "")}
                                                        </Grid>
                                                        <Grid item xs={9} sm={10} md={10} sx={{ marginLeft: "16px", cursor: "pointer" }}> 
                                                            <Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.name}</Typography>
                                                            <Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{product.description}</Typography>
                                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}> 
                                                                {product.price && product.price != 0 && product.price != "" && product.price != null ?
                                                                    <Typography variant="title8">${product.price.toLocaleString("en-US")} {product.currency}</Typography>
                                                                :null}
                                                                {product.promotion != "" && product.promotion != null ?
                                                                    <Box sx={{display: "flex", backgroundColor: "sunset", marginLeft: "8px", height: "20px", borderRadius: "12px", paddingLeft: "8px", paddingRight: "8px", alignItems: "center"}}>
                                                                        <Typography variant="body4" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, color: "white", marginTop: "2px" }}>{product.promotion}</Typography>
                                                                    </Box>
                                                                :null}
                                                            </Box>
                                                            <Typography variant="body2" sx={{ color: "gray", display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.address && product.address.name != "" ? product.address.name : ""}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }}/>
                                                </div>
                                    }else{
                                        return  <Box>
                                                    <Typography>Producto no disponible</Typography>
                                                </Box>
                                    }
                                })}
                                {loadingMore == true ? 
                                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <Typography>
                                            Cargando...
                                        </Typography>
                                    </Box>
                                :
                                    !allProductsDisplayed ?
                                        <Button sx={{ marginTop: "8px" }} onClick={()=>moreProducts()}>
                                            <Typography variant='title8' sx={{ color: colors.primary_green }}>Ver más...</Typography>
                                        </Button>
                                    :null
                                }
                                
                            </Grid>
                        :
                            <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Typography>
                                    No hay productos
                                </Typography>
                            </Box>
                        }
                    </Grid>
                </Container>
            :
                <Container>
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            Hubo un problema al cargar los productos.
                        </Typography>
                    </Box>
                </Container>
        )
    } catch (error) {
        return <Container>
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            Hubo un problema al cargar los productos.
                        </Typography>
                    </Box>
                </Container>
    }
}

export default StoreProductsScreen;