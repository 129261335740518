const constants = {
    // LIVE
    mode: "",
    URL: {
        root: 'https://api.treembo.com/',
        api: 'https://api.treembo.com/api/',
        home: 'https://welcome.treembo.com/',
        mode: "",
        treemboStore: "xBlnN73SKcHhVJCwBj7d"
    },
    
    // TEST
    // mode: "test-",
    // URL: {
    //     root: 'https://apidev.treembo.com/',
    //     api: 'https://apidev.treembo.com/api/',
    //     home: 'https://test-welcome.treembo.com/',
    //     mode: "test-",
    //     treemboStore: "bx5zshtGLEmVJx0bmhF0",
    // },

    // GENERAL
    uploadedImages: 'https://s3.amazonaws.com/uploads.treembo.com/',
    algolia: {
		appId: "CMJ7KA61PJ",
		searchKey: "bc873e257907192b689777b5e44c3695",
	},
}

export default constants;