import { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { AccessTimeOutlined, ChevronLeft } from '@mui/icons-material';
import { doc, getDoc } from "firebase/firestore";
import { db } from 'services/firebase/firebaseConfig';
import { getAnalytics, logEvent } from 'firebase/analytics'
import imgSuffix from 'common/image-suffix';
import moment from "moment";
import constants from 'common/constants';
import colors from "theme/colors";
import { useParams, useNavigate } from 'react-router-dom';
import TiktokPixel from 'utils/tiktok_pixel';

const NewScreen = (props) => {
	const params = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
	const [newId, setNewId] = useState(params.newId);
	const [newInfo, setNewInfo] = useState(null);

	useEffect(() => {
        const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
            firebase_screen: "Noticia_Web", 
            firebase_screen_class: "Noticia_Web"
		});
        TiktokPixel.pageView();
		if(newId != ""){
			getNew()
		}else{
			setLoading(false)
		}
	},[])

	const getNew = async () => {
        try {
            const docRefNew = doc(db, constants.mode + "news", newId);
            const docSnapNew = await getDoc(docRefNew);
            //console.log("NEW_SNAP: ", docSnapNew.data())
            if(docSnapNew.data()){
                setNewInfo(docSnapNew.data());
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
			console.log("Get new error: ", error)
        }
	}

    try{
        if(loading){
			return <Container>
						<Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
							<Typography>
								Cargando...
							</Typography>
						</Box>
					</Container>
		}
        return (
            newInfo != null ? 
                <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px" }}>
                    <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", marginTop: "16px", backgroundColor: "white" }}>
                        <Grid item xs={12} sx={{  }}>
							<ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={()=>{navigate(-1)}}/>
						</Grid>
                        <Grid item sx={{ display: "flex", backgroundColor: "white", width: "100%"}}>
                            {newInfo.images ?
                                <img style={{width: "100%", minHeight: "200px"}} src={newInfo.images[0] + imgSuffix.lg}/>
                            :
                                <div/>
                            }
                        </Grid>
                        <Grid item sx={{ display: "flex", flexDirection: "column", backgroundColor: "white", marginTop: "8px", width: '100%'}}>
                            <Typography variant="title5" sx={{ fontWeight: "bold" }}>{newInfo.name}</Typography>
                            <Grid sx={{ display: "flex", flexDirection: "row" }}>
                                <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography variant="body2">Vía:&nbsp;</Typography>
                                    <a href={newInfo.sourceLink} target="_blank" rel="noopener noreferrer">
                                        <Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, cursor: "pointer" }}>{newInfo.source}</Typography>
                                    </a>
                                </Grid>
                                <AccessTimeOutlined fontSize="small" sx={{ marginLeft: "16px" }}/>
                                <Typography variant="body2" sx={{ paddingLeft: "4px" }}>{moment(newInfo.createdAt.toDate()).format('DD/MM/YYYY')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: "flex", flexDirection: "column", width: '100%'}}>
                            <div dangerouslySetInnerHTML={{ __html: newInfo.html }} style={{fontSize: "16px"}}/>
                        </Grid>
                        <Grid item sx={{ display: "flex", flexDirection: "column", width: '100%', overflow: 'hidden'}}>
                            <a style={{ width: '100%'}} href={newInfo.sourceLink} target="_blank" rel="noopener noreferrer">
                                <Typography variant="body2" sx={{ color: colors.primary_green, cursor: "pointer",  width: '100%'}}>
                                    {newInfo.sourceLink}
                                </Typography>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            :   <Container>
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            Noticia no disponible
                        </Typography>
                    </Box>
                </Container>
        )
    }catch(error){
        console.log("ERROR_NEW_LOAD: ", error)
		return  <Container>
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            Hubo un problema al cargar la noticia.
                        </Typography>
                    </Box>
                </Container>
    }
}

export default NewScreen;