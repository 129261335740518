import typography from 'theme/typography';
import colors from 'theme/colors';

let sx = {
	appBarButton: {
		marginRight: "30px",
		...typography.body3,
		textTransform: "none",
		color: colors.main_white,
		fontSize: "0.859rem"
	},
}

export default sx;